




















import { Vue, Component, Watch } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import UpcomingBattles from '@/components/battlesList/UpcomingBattles.vue';
import LiveBattles from '@/components/battlesList/LiveBattles.vue';
import FinishedBattles from '@/components/battlesList/FinishedBattles.vue';
import BattleListFooter from '@/components/battlesList/BattleListFooter.vue';
import Loader from '@/components/Loader.vue';
import { BattleModule } from '@/store/modules/battle';
import { language, LanguageModule } from '@/store/modules/language';
import FollowRulesModal from '@/components/FollowRulesModal.vue';
import { ContentWithLinks } from '@/store/types';
import eventBus from '@/utils/eventBus';

@Component({
    components: { FollowRulesModal, Loader, BattleListFooter, FinishedBattles, LiveBattles, UpcomingBattles, Header }
})
export default class BattlesListPage extends Vue {
    private loading: boolean = false;

    private followRulesContent: ContentWithLinks | null = null;

    private get currentLanguage(): language {
        return LanguageModule.currentLanguage;
    }

    private mounted(): void {
        eventBus.emit('changeClickSrc', '/img/moni-eye.svg');
    }

    private created(): void {
        this.getBattles();

        eventBus.on('followRules', (payload: ContentWithLinks | undefined) => {
            console.log(payload);
            if (payload) {
                this.followRulesContent = payload;
            }
        });
    }

    private closeFollowRulesModal(): void {
        this.followRulesContent = null;
    }

    @Watch('currentLanguage')
    private onLanguageChange(): void {
        this.getBattles();
    }

    private async getBattles(): Promise<void> {
        this.loading = true;

        await Promise.all([
            BattleModule.getUpcoming(),
            BattleModule.getLive(),
            BattleModule.getFinished(),
        ]);

        this.loading = false;
    }
}
