


































import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';
import { ContentWithLinks } from '@/store/types';
import AppButton from '@/components/controls/AppButton.vue';
import AppLink from '@/components/controls/AppLink.vue';

@Component({ components: { AppButton, AppLink } })
export default class FollowRulesModal extends Vue {
    @Model('close')
    private readonly isShown!: boolean;

    @Emit('close')
    private closeModal(): false {
        return false;
    }

    @Prop({ type: Object, default: () => ({}) })
    private rules!: ContentWithLinks;
}
