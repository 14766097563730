
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class UpcomingBattlesPagination extends Vue {
    @Prop({ type: Boolean, default: false })
    private visible!: boolean;
    
    private showMore(): void {
        const balloon = document.querySelector('.upcomingBattlesPagination__balloon') as HTMLElement;
        const position = getElementPosition(balloon);
        const balloonClone = balloon.cloneNode(false) as HTMLElement;
        const step = 10;

        balloonClone.style.left = `${position.left}px`;
        balloonClone.style.top = `${position.top}px`;
        balloonClone.style.zIndex = '4';
        document.body.appendChild(balloonClone);

        let currentTranslate = 0;

        const balloonUp = () => {
            currentTranslate += step;

            balloonClone.style.transform = `translate(-50%, ${-currentTranslate}px)`;

            if (currentTranslate < document.body.clientHeight) {
                requestAnimationFrame(balloonUp);
            } else {
                balloonClone.remove();
            }
        };

        requestAnimationFrame(balloonUp);

        this.$emit('showMore', true);
    }
}

function getElementPosition(el: HTMLElement): { top: number, left: number } {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}
