











import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import BattleItemButton from '@/components/battlesList/BattleItemButton.vue';
import { BattleModule } from '@/store/modules/battle';
import { UserModule } from '@/store/modules/user';

@Component({
    components: { BattleItemButton },
})
export default class UpcomingBattleInfo extends Vue {
    @Prop({ default: '' })
    private id!: string;

    @Prop({ default: '' })
    private finishDate!: string;

    @Prop({ default: '' })
    private startDate!: string;

    @Prop({ default: '' })
    private openDate!: string;

    @Prop({ type: Boolean, default: false })
    private iamParticipant!: boolean;

    private get currentTime(): Date {
        return BattleModule.currentTime;
    }

    private get isAuthed(): boolean {
        return UserModule.isAuthed;
    }

    private get closestDate(): string {
        if (Number(this.currentTime) < Number(new Date(this.openDate))) {
            return this.openDate;
        } else if (Number(this.currentTime) < Number(new Date(this.startDate))) {
            return this.startDate;
        } else {
            return this.finishDate;
        }
    }

    private get battleStatusText(): string {
        if (this.closestDate === this.openDate) {
            return this.$t('registrationWillBegin') as string;
        } else if (this.closestDate === this.startDate && this.iamParticipant) {
            return this.$t('battleWillBegin') as string;
        } else {
            return this.$t('registerWillEnd') as string;
        }
    }

    private get buttonType(): 'opened' | 'disabled' | 'active' {
        if (this.closestDate === this.startDate && this.iamParticipant) {
            return 'active';
        } else if (this.closestDate === this.openDate) {
            return 'disabled';
        } else  {
            return 'opened';
        }
    }

    @Watch('closestDate')
    private onClosestDateChange(val: string): void {
        if (val === this.finishDate) {
            BattleModule.changeStateToLive(this.id);
        }
    }

    private goToBattle(): void {
        if (this.buttonType === 'disabled') {
            return;
        } else  {
            this.$router.push({ name: 'BattleViewHandler', params: { id: this.id } });
        }
    }
}
