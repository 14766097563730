

































































import { Vue, Component } from 'vue-property-decorator';
import AppButton from '@/components/controls/AppButton.vue';
import eventBus from '@/utils/eventBus';

@Component({
    components: { AppButton }
})
export default class BattleListFooter extends Vue {
    private openMoni(): void {
        window.open('https://getmoni.io', '_blank');
    }

    private setHeartCursor(): void {
        eventBus.emit('changeClickSrc', '/img/battlesList/pixelLove.svg');
    }

    private setDefaultCursor(): void {
        eventBus.emit('changeClickSrc', '/img/moni-eye.svg');
    }
}
