






















































































import { Vue, Watch, Component } from 'vue-property-decorator';
import { IBattleInfo } from '@/store/types';
import { BattleModule } from '@/store/modules/battle';
import { UserModule } from '@/store/modules/user';
import Pagination from '@/components/Pagination.vue';

@Component({
    components: { Pagination }
})
export default class FinishedBattles extends Vue {
    private readonly countPerPage: number = 10;

    private currentPage: number = 0;
    
    private filterType: 'all' | 'mine' = 'all';

    private get battles(): IBattleInfo[] {
        let battles = Object.values(BattleModule.finishedBattles);

        if (this.filterType === 'mine') {
            battles = battles.filter(battle => battle.iamParticipant);
        }

        return battles.sort((a, b) => +new Date(b.finish_date) - +new Date(a.finish_date));
    }

    private get isFilterShown(): boolean {
        return this.isAuthed && this.battles.some(battle => battle.iamParticipant);
    }

    private get currentBattles(): IBattleInfo[] {
        return this.battles.slice(this.countPerPage * this.currentPage, this.countPerPage * this.currentPage + this.countPerPage);
    }

    private get isAuthed(): boolean {
        return UserModule.isAuthed;
    }

    @Watch('isAuthed')
    private onAuthedChange(val: boolean): void {
        if (!val) {
            this.setFilter('all');
        }
    }

    private setFilter(filter: 'all' | 'mine'): void {
        if (filter !== this.filterType) {
            this.filterType = filter;
            this.currentPage = 0;
        }
    }

    private goToBattle(id: string): void {
        this.$router.push({ name: 'BattleViewHandler', params: { id } });
    }
}
