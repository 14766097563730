
























import { Vue, Component } from 'vue-property-decorator';
import BattleItem from '@/components/battlesList/BattleItem.vue';
import AppButton from '@/components/controls/AppButton.vue';
import { IBattleInfo } from '@/store/types';
import { BattleModule } from '@/store/modules/battle';

@Component({
    components: { BattleItem, AppButton },
})
export default class LiveBattles extends Vue {
    private get battles(): IBattleInfo[] {
        return Object.values(BattleModule.liveBattles).sort((a, b) => +new Date(b.finish_date) - +new Date(a.finish_date));
    }
}
