








import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BattleItemButton extends Vue {
    @Prop({ default: 'opened' })
    private type!: 'opened' | 'disabled' | 'active';

    private get word(): string {
        if (this.type === 'active') {
            return this.$t('takePart') as string;
        }

        return this.$t('join') as string;
    }
}
