

































import { Vue, Component } from 'vue-property-decorator';
import BattleItem from '@/components/battlesList/BattleItem.vue';
import { IBattleInfo } from '@/store/types';
import { BattleModule } from '@/store/modules/battle';
import UpcomingBattleInfo from '@/components/battlesList/UpcomingBattleInfo.vue';
import UpcomingBattlesPagination from '@/components/battlesList/UpcomingBattlesPagination.vue';

@Component({
    components: { UpcomingBattlesPagination, UpcomingBattleInfo, BattleItem },
})
export default class UpcomingBattles extends Vue {
    private readonly countPerPage: number = 4;

    private currentCount: number = this.countPerPage;

    private get battles(): IBattleInfo[] {
        return Object.values(BattleModule.upcomingBattles).sort((a, b) => +new Date(a.open_date) - +new Date(b.open_date));
    }

    private get currentBattles(): IBattleInfo[] {
        return this.battles.slice(0, this.currentCount);
    }

    private showMore(): void {
        this.currentCount += this.countPerPage;
    }
}
