


















































import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import BattleInfo from '@/components/battlesList/BattleInfo.vue';
import { IBattleInfo } from '@/store/types';
import { UserModule } from '@/store/modules/user';
import eventBus from '@/utils/eventBus';
import { BattleModule } from '@/store/modules/battle';

@Component({
    components: { BattleInfo }
})
export default class BattleItem extends Vue {
    @Prop({ type: Boolean, default: false })
    private live!: boolean;

    @Prop({ default: () => {} })
    private battleInfo!: IBattleInfo;

    private get isAuthed(): boolean {
        return UserModule.isAuthed;
    }

    private get currentTime(): Date {
        return BattleModule.currentTime;
    }

    private get isDisabled(): boolean {
        return new Date(this.battleInfo.open_date) > this.currentTime;
    }

    @Watch('currentTime')
    private onCurrentTimeChange(val: Date): void {
        if (this.live && val >= new Date(this.battleInfo.finish_date)) {
            BattleModule.changeStateToFinished(this.battleInfo.id);
        }
    }

    private goToBattle(): void {
        if (Date.now() < +new Date(this.battleInfo.open_date)) {
            return;
        }

        if (!this.isAuthed && !this.live) {
            eventBus.emit('pleaseAuth');

            return;
        }

        if (!this.battleInfo.can_enter && !this.live) {
            const rules = this.battleInfo.rules;

            if (rules) {
                eventBus.emit('followRules', rules[0]?.content);
            }

            return;
        }

        this.$router.push({ name: 'BattleViewHandler', params: { id: String(this.battleInfo.id) } });
    }
}
